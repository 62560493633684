import React from 'react'
import styled from 'styled-components'

// Elements
import ParseContent from 'components/shared/ParseContent'
import { FormCSS } from 'styles/FormStyle'

// Components
import FormDuuf, {
  FormDuufGroup,
  FormDuufSubmit,
} from 'components/related/Form'
import Loading from 'components/shared/Loading'

const StyledFormDuuf = styled(FormDuuf)`
  ${FormCSS}
`
interface FormContactProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Form
}

const FormContact: React.FC<FormContactProps> = ({ fields }) => (
  <section>
    <div className="container py-5">
      <ParseContent content={fields.description || ''} />
      <StyledFormDuuf id={1} className="mt-5" generate={false}>
        <div className="row justify-content-center">
          <div className="col-9">
            <div className="row">
              <div className="col-7">
                <FormDuufGroup hideIds={[2]} />
              </div>
              <div className="col-5">
                <FormDuufGroup showIds={[2]} />
              </div>
            </div>
            <FormDuufSubmit />
          </div>
        </div>
      </StyledFormDuuf>
    </div>
  </section>
)

export default FormContact
